import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import "./header.css"

const Header = ({ siteTitle, fillMenu }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", e => {
      const scrollpos = window.pageYOffset | document.body.scrollTop
      if (scrollpos > 50) {
        document.body.classList.add("is-scrolled")
      } else {
        document.body.classList.remove("is-scrolled")
      }
    })
  }, [])

  function buyTickets(e) {
    e.preventDefault()

    /* eslint-disable */
    if (typeof fbq !== "undefined") {
      fbq("trackCustom", "Get tickets")
    }
    /* eslint-enable */

    window.open(e.target.href, "_blank")
  }

  return (
    <header class={`header ${fillMenu ? "is-filled" : ""}`}>
      <button onClick={() => setIsOpen(!isOpen)} class="header__menu-button">
        <div class="header__hamburger">
          <span></span>
          <span></span>
          <span></span>
        </div>
        Menu
      </button>

      <div class={`header__content ${isOpen ? "is-open" : ""}`}>
        <button onClick={() => setIsOpen(false)} class="header__close-button">
          &#10005;
        </button>
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">Info</Link>
            </li>
            <li>
              <Link to="/lineup">Lineup</Link>
            </li>
            <li>
              <Link to="/workshops">Workshops & Ceremonies</Link>
            </li>
            <li>
              <a
                rel="noopener noreferrer"
                onClick={buyTickets}
                class="button"
                href="https://pachabuela-kulam-inanitah-fire-season-gathering.eventbrite.com"
                target="_blank"
              >
                Tickets
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

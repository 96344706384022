import React from "react"

export default function Text({ heading, subheading, body }) {
  return (
    <div class="mt-5 mb-5">
      <div class="container is-padded mb-5">
        {heading && <h1 class="h1 is-text-center">{heading}​</h1>}
        {subheading && <p class="h2 is-text-center">{subheading}</p>}
      </div>
      <div class="container is-narrow is-padded is-small mb-5">
        <div class="content">
          <div dangerouslySetInnerHTML={{ __html: body }}></div>
        </div>
      </div>
    </div>
  )
}

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children, hasHero, fillMenu }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header fillMenu={fillMenu} siteTitle={data.site.siteMetadata.title} />
      <main class={hasHero && "has-hero"}>{children}</main>
      <footer class="footer">
        <div class="container">
          <div class="content is-light">
            <h2 class="h2">Contact us</h2>
            <a class="h3" href="mailto:4ecstatic@gmail.com">
              4ecstatic@gmail.com
            </a>
          </div>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
